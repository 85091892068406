import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { HeroGlossaryV2 } from "../components/hero";
import getMetatags from "../util/getMetatags";
import Seo from "../components/Seo";
//import ResourceTag from "../components/resource-tag"
import { Teasers } from "../components/resources";

function ResourcesPage({
  data: {
    nodeResourcesLobby,
    allNodeBlog,
    allNodeEbook,
    allNodeWhitePaper,
    allNodeVideo,
    allNodeWebinar
  },
  location,
  path,
}) {
  

  const [keywords, setKeywords] = useState("");
  const [category, setCategory] = useState("");
  const [resources, setResources] = useState([]);

  useEffect(() => {
    let categoryInit = "";
    const parts = location.search.substring(1).split("=");
    if (parts[0] === "category") {
      categoryInit = parts[1];
    }
    setCategory(categoryInit);
  }, [location]);

  useEffect( () => {

    const  DoInitial = async () => {
      const items0 = allNodeBlog.nodes.map((item) => {
        let search = "";

        if (item.title) {
          search += item.title.toLowerCase();
        }
        if (item.teaser) {
          search += item.teaser.toLowerCase();
        }
        if (item?.summary?.processed) {
          search += item.summary.processed.replace(/<[^>]*>?/gm, "").toLowerCase();
        }

        return {
          ...item,
          type: item?.relationships?.category?.name,
          category: item?.relationships?.category?.name,
          search: search,
        };
      });

      const items1 = allNodeEbook.nodes.map((item) => {
        let search = "";

        if (item.title) {
          search += item.title.toLowerCase();
        }
        if (item.teaser) {
          search += item.teaser.toLowerCase();
        }

        return {
          ...item,
          type: "eBook." + item.type,
          category: item.type === "webinar" ? "Webinar" : "eBook",
          search: search,
        };
      });

      const items2 = allNodeWhitePaper.nodes.map((item) => {
        let search = "";

        if (item.title) {
          search += item.title.toLowerCase();
        }
        if (item.teaser) {
          search += item.teaser.toLowerCase();
        }
        return {
          ...item,
          type: "eBook",
          category: "eBook",
          search: search,
        };
      });

      const items3 = allNodeVideo.nodes.map((item) => {
        let search = "";

        if (item.title) {
          search += item.title.toLowerCase();
        }
        if (item.teaser) {
          search += item.teaser.toLowerCase();
        }

        return {
          ...item,
          type: "Videos",
          category: "Videos",
          search: search,
        };
      });

      const items4 = allNodeWebinar.nodes.map((item) => {
        let search = "";

        if (item.title) {
          search += item.title.toLowerCase();
        }
        if (item.teaser) {
          search += item.teaser.toLowerCase();
        }

        return {
          ...item,
          type: "Webinar",
          category: "Webinar",
          search: search,
        };
      });

      const byDates = (a, b) => {
        //console.log(a.date, b.date)
        if (a.date > b.date) {
          return -1;
        }
        if (a.date < b.date) {
          return 1;
        }
        return 0;
      };

      const itemsAll = [...items0, ...items1, ...items2, ...items3, ...items4].sort(
        byDates
      );

      //console.log(itemsAll);
      setResources(itemsAll);
    };

    DoInitial();
  }, [allNodeBlog, allNodeEbook, allNodeWhitePaper, allNodeVideo, allNodeWebinar]);

  const reset = () => {
    setKeywords("");
    setCategory("");
  };

  const { title, title2, description } = nodeResourcesLobby;
  const seo = getMetatags(
    nodeResourcesLobby.metatag,
    nodeResourcesLobby.relationships.OGImage?.localFile?.publicURL
  );

  const items = filter(resources, keywords, category);
  //console.log(items)
  return (
    <Layout showCta={false}>
      <Seo {...seo} />
      <HeroGlossaryV2
        title={title2}
        tag={title}
        description={description}
        bgColor="#E6F0FF"
      >
        <ResourcesSearch value={keywords} onChange={setKeywords} />
      </HeroGlossaryV2>
      <Categories category={category} onChange={setCategory} />

      <Teasers classNameSection="py-12 lg:py-0" items={items} />

      <TeasersZeroResults items={items}>
        <button onClick={reset} className="underline font-bold text-blue">
          clear filters
        </button>
      </TeasersZeroResults>
    </Layout>
  );
}

const filter = (items, keywords, category = "") => {
  
  const filtered = items
    .filter((e) => e.search.includes(keywords.toLowerCase()))
    .filter((e) => category === "" || e.category === category);

  return filtered;
};

const ResourcesSearch = ({ value, onChange }) => {
  return (
    <div className="max-w-[500px] mx-auto lg:pt-12">
      <input
        type="search"
        value={value}
        className="w-full h-12 pl-12 pr-4 rounded-full border border-gray-500 bg-white bg-no-repeat bg-left bg-[center_left_9px]"
        placeholder="Search Resources..."
        style={{ backgroundImage: `url('/magnifying-glass.svg')` }}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
    </div>
  );
};

const Categories = ({ category, onChange }) => {
  const cats = [
    { label: "All", value: "" },
    { label: "Videos", value: "Videos" },
    { label: "Blog posts", value: "Blog" },
    { label: "News", value: "News" },
    { label: "Webinars", value: "Webinar" },
    { label: "eBooks", value: "eBook" },
  ];

  const links = cats.map((item, index) => (
    <button
      className={
        "px-6 py-3 border-b  text-2xl text-[#8F90A6] " +
        (item.value === category
          ? " border-b-4 border-blue text-blue font-bold "
          : " border-b-4 border-white ")
      }
      key={index}
      onClick={() => {
        //console.log(item.value)
        onChange(item.value);
      }}
      id={item.value}
    >
      {item.label}
    </button>
  ));

  return (
    <section className="lg:py-12 hidden lg:block">
      <div className="container text-center  ">
        <div className="flex flex-row justify-center border-b w-fit mx-auto">
          {links}
        </div>
      </div>
    </section>
  );
};

const TeasersZeroResults = ({ items, children }) => {
  if (items.length > 0) return false;
  return (
    <section className="lg:py-4 lg:pb-32">
      <div className="container text-center space-y-12 ">
        <div className="">No resources found.</div>

        <div className="">{children}</div>
      </div>
    </section>
  );
};

export default ResourcesPage;

/* export const Head = () => <title>Overview Page</title> */

//field_cta_1
export const query = graphql`
  query resources($id: String!, $status: [Boolean!]!) {
    nodeResourcesLobby(id: { eq: $id }) {
      id
      title
      path {
        alias
      }
      metatag {
        attributes {
          content
          name
        }
        tag
      }
      title2: field_title
      description: field_description2
      image: field_image {
        alt
      }
      relationships {
        image: field_image {
          ...Image
        }
      }
    }
    allNodeBlog(filter: { status: { in: $status } }) {
      nodes {
        id
        title
        path {
          alias
        }
        date: field_date
        teaser: field_description2
        teaser_link: field_cta_1 {
          uri
        }
        image: field_hero_image {
          alt
          height
          width
        }
        summary: field_body_summary {
          processed
        }
        relationships {
          image: field_hero_image {
            localFile {
              publicURL
            }
          }
          category: field_category {
            name
          }
        }
      }
    }
    allNodeEbook(filter: { status: { in: $status } }) {
      nodes {
        id
        title
        path {
          alias
        }
        date: field_date
        teaser: field_description2
        image: field_hero_image {
          alt
          height
          width
        }
        type: field_type
        relationships {
          image: field_hero_image {
            localFile {
              publicURL
            }
          }
        }
      }
    }
    allNodeWhitePaper(filter: { status: { in: $status } }) {
      nodes {
        id
        title
        path {
          alias
        }
        date: field_date
        teaser: field_description2
        image: field_hero_image {
          alt
          height
          width
        }
        relationships {
          image: field_hero_image {
            localFile {
              publicURL
            }
          }
        }
      }
    }
    allNodeVideo(filter: { status: { in: $status } }) {
      nodes {
        id
        title

        date: field_date

        image: field_hero_image {
          alt
          height
          width
        }
        relationships {
          image: field_hero_image {
            localFile {
              publicURL
            }
          }
          video: field_video_file {
            localFile {
              publicURL
            }
          }
        }
      }
    }
    allNodeWebinar(filter: { status: { in: $status } }) {
      nodes {
        id
        title
        path {
          alias
        }
        date: field_date
        teaser: field_description2
        image: field_hero_image {
          alt
          height
          width
        }
        relationships {
          image: field_hero_image {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`;
